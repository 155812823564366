import { graphql } from "gatsby";
import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";
import Nav from "../components/Nav/Nav";
import Intro from "../modules/Intro/Intro";
import ProjectFeed from "../modules/ProjectFeed/ProjectFeed";
import PostFeed from "../modules/PostFeed/PostFeed";
import Section from "../components/Section/Section";
import FeedFilter from "../components/FeedFilter/FeedFilter";
// import Footer from "../modules/Footer/Footer";
import PageFooter from "../modules/PageFooter/PageFooter";
import { register } from "swiper/element/bundle";

const ProjectPage = ({
  data: {
    wp: {
      themeOptions: {
        themeOptions: { storiesHeading },
      },
    },
    allWpSector,
    allWpRole,
    wpPage,
    allWpProject,
    allWpPost,
    allWpPage,
  },
}) => {
  const storiesHeadingRef = useRef(null);
  const [pageState, setPageState] = useState("stories");
  const [currentHeading, setCurrentHeading] = useState(storiesHeading);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [type, setType] = useState("projects");
  const {
    template: { projectsTemplate },
    title,
    slug,
    seo,
  } = wpPage;
  const pageTitle = title ? `${title} -` : "";

  const homePage = allWpPage.nodes[0].template.pageModules.modules.find(
    (module) =>
      module.fieldGroupName === "Template_Modules_Pagemodules_Modules_Intro"
  );

  const taxonomies = [...allWpSector.nodes, ...allWpRole.nodes];

  register();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const perspectivesParam = urlParams.get("perspectives");

    if (perspectivesParam === "true") {
      setType("posts");
    }
  }, []);

  useEffect(() => {
    setTimeout(function () {
      window.scroll.update();

      document.body.classList.remove("hidden-burger");
      document.querySelector(".feed-filter").classList.remove("active");
    }, 1);
  }, [type]);

  // Stories Heading Filter
  useEffect(() => {
    const storiesHeadingEl = storiesHeadingRef.current;
    const links = storiesHeadingEl.querySelectorAll("a");

    const clickHandler = (e) => {
      e.preventDefault();
      setCurrentFilter(e.target.href.split("#")[1]);
    };

    for (const link of links) {
      link.addEventListener("click", clickHandler);
    }

    return () => {
      for (const link of links) {
        link.removeEventListener("click", clickHandler);
      }
    };
  }, [storiesHeadingRef]);

  useEffect(() => {
    handleHeading();

    document.body.classList.remove("hidden-burger");
    document.querySelector(".feed-filter").classList.remove("active");

    setTimeout(function () {
      window.scroll.update();
    }, 1);
  }, [currentFilter]);

  function toggleFilter() {
    document.body.classList.toggle("hidden-burger");
    document.querySelector(".feed-filter").classList.toggle("active");
  }

  function handleHeading() {
    const filter = taxonomies.find(
      (taxonomy) => taxonomy.slug === currentFilter
    );

    if (filter) {
      const title = filter?.name;
      const desc = filter?.description || "";

      setCurrentHeading(
        title.length > 0 && desc.length > 0
          ? `
        <span>${title}</span>
        ${desc}
      `
          : storiesHeading
      );
    } else {
      setCurrentHeading(storiesHeading);
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle} Seesaw Studio</title>
      </Helmet>

      <Seo seo={seo} />
      <Nav position="top" slug={slug} templateType="stories" />
      <FeedFilter
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        toggleFilter={toggleFilter}
      />
      <Intro
        classes=" project-intro"
        pageState={pageState}
        setPageState={setPageState}
        imageLeft={homePage.imageLeft}
        imageRight={homePage.imageRight}
      />
      <Section classes="stories-header">
        <div className="stories-header__column">
          {storiesHeading && (
            <h4
              ref={storiesHeadingRef}
              className="stories-header__heading"
              dangerouslySetInnerHTML={{ __html: currentHeading }}
            />
          )}

          <div className="stories-header__buttons">
            <div class="switches">
              <button
                className={`button button--light${
                  type === "projects" ? " active" : ""
                }`}
                onClick={() => setType("projects")}
              >
                Projects
              </button>

              <button
                className={`button button--light${
                  type === "posts" ? " active" : ""
                }`}
                onClick={() => setType("posts")}
              >
                Perspectives
              </button>
            </div>

            {type === "projects" && (
              <button className="button button--light" onClick={toggleFilter}>
                Filter
                {currentFilter && (
                  <div>: {currentFilter.replace(/-/g, " ")}</div>
                )}
                <span />
              </button>
            )}
          </div>
        </div>
      </Section>
      {type === "projects" ? (
        <ProjectFeed currentFilter={currentFilter} projects={allWpProject} />
      ) : (
        <PostFeed posts={allWpPost} />
      )}

      <PageFooter pageState={"stories"} slug="/" label="Studio" />

      {/* <Footer data={allWpPage} /> */}
    </>
  );
};

export default ProjectPage;

export const query = graphql`
  query ($id: String!) {
    wp {
      themeOptions {
        themeOptions {
          storiesHeading
        }
      }
    }
    allWpSector {
      nodes {
        name
        slug
        description
      }
    }
    allWpRole {
      nodes {
        name
        description
        slug
      }
    }
    allWpPost(sort: { fields: dateGmt, order: DESC }) {
      nodes {
        databaseId
        title
        excerpt
        uri
        date(formatString: "DD-MM-YY")
        postModules {
          readingTime
        }
        featuredImage {
          node {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      slug
      title
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      template {
        ... on WpTemplate_ProjectsWork {
          templateName
          projectsTemplate {
            heading
            text1
            text2
          }
        }
      }
    }
    allWpProject(sort: { fields: menuOrder, order: ASC }) {
      nodes {
        menuOrder
        databaseId
        title
        slug
        excerpt
        featuredImage {
          node {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        projectVideos {
          videoThumbnailLandscape
          videoThumbnailPortrait
        }
        landscapeImage {
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        projectClients {
          nodes {
            slug
            name
          }
        }
        roles {
          nodes {
            slug
          }
        }
        types {
          nodes {
            slug
          }
        }
        projectsSide {
          featuredProject
          hiddenProject
        }
      }
    }
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      nodes {
        template {
          ... on WpTemplate_Modules {
            templateName
            pageModules {
              modules {
                ... on WpTemplate_Modules_Pagemodules_Modules_Intro {
                  fieldGroupName
                  imageLeft {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                        )
                      }
                    }
                    altText
                  }
                  imageRight {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: DOMINANT_COLOR
                          quality: 100
                          breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                        )
                      }
                    }
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
