import React from "react";
import Section from "../../components/Section/Section";
import ProjectItem from "../../components/ProjectItem/ProjectItem";
import CardCursor from "../../components/CardCursor/CardCursor";

import gsap from "gsap";

import "./ProjectFeed.scss";

const ProjectFeed = ({ currentFilter, projects }) => {
  let orderedProjects =
    projects?.nodes?.sort((a, b) => {
      const orderA = a.menuOrder === null ? 0 : a.menuOrder;
      const orderB = b.menuOrder === null ? 0 : b.menuOrder;

      return orderA - orderB;
    }) || [];

  if (currentFilter) {
    orderedProjects = orderedProjects.filter((project) => {
      return (
        project.roles.nodes.some((role) => role.slug === currentFilter) ||
        project.types.nodes.some((type) => type.slug === currentFilter)
      );
    });
  } else {
    orderedProjects = orderedProjects.filter(
      (project) => !project?.projectsSide?.hiddenProject
    );
  }

  return (
    <>
      <Section classes="project-feed">
        <div className="project-feed__row">
          <CardCursor label="View" targetCard=".zoom_image__image">
            <div className={`project-feed__feed `}>
              {orderedProjects.length > 0 ? (
                orderedProjects.map((project, i) => {
                  const index = i + 1;

                  return (
                    <ProjectItem
                      key={project.databaseId}
                      index={index}
                      project={project}
                    />
                  );
                })
              ) : (
                <p className="project-feed__not-found">No Projects Found</p>
              )}
            </div>
          </CardCursor>
        </div>
      </Section>
    </>
  );
};

export default ProjectFeed;
