import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "./FeedFilter.scss";

const FeedFilter = ({ currentFilter, setCurrentFilter, toggleFilter }) => {
  const {
    wp: {
      themeOptions: {
        themeOptions: { archiveLink, archiveText },
      },
    },
    allWpSector,
    allWpDiscipline,
  } = useStaticQuery(graphql`
    query FilterQuery {
      wp {
        themeOptions {
          themeOptions {
            archiveLink {
              title
              url
            }
            archiveText
          }
        }
      }
      allWpSector {
        nodes {
          name
          slug
        }
      }
      allWpDiscipline {
        nodes {
          name
          slug
        }
      }
    }
  `);

  return (
    <div className="feed-filter">
      <h3 className="feed-filter__heading">Filter</h3>

      <span className="feed-filter__close" onClick={toggleFilter}>
        <span>—</span>
      </span>

      <div className="feed-filter__list">
        {allWpDiscipline?.nodes && (
          <div className="feed-filter__list-discipline">
            <h4 className="feed-flter__list-subheading">Discipline</h4>

            {allWpDiscipline.nodes.map((discipline) => (
              <span
                key={discipline.slug}
                className={`item${
                  currentFilter === discipline.slug ? " active" : ""
                }`}
                onClick={() =>
                  setCurrentFilter(() =>
                    currentFilter === discipline.slug ? null : discipline.slug
                  )
                }
              >
                {discipline.name}
              </span>
            ))}
          </div>
        )}

        {allWpSector?.nodes && (
          <div className="feed-filter__list-sector">
            <h4 className="feed-flter__list-subheading">Sector</h4>
            {allWpSector.nodes.map((sector) => (
              <span
                key={sector.slug}
                className={`item${
                  currentFilter === sector.slug ? " active" : ""
                }`}
                onClick={() =>
                  setCurrentFilter(() =>
                    currentFilter === sector.slug ? null : sector.slug
                  )
                }
              >
                {sector.name}
              </span>
            ))}
          </div>
        )}
      </div>

      {archiveLink && archiveText && (
        <div className="feed-filter__archive">
          <h4 className="feed-filter__archive-heading">Archive</h4>

          <div className="feed-filter__archive-text">
            <AniLink
              direction="right"
              cover
              to={archiveLink.url}
              duration={1.5}
              bg="#f2f3f0"
            >
              {archiveLink.title}
            </AniLink>

            <span dangerouslySetInnerHTML={{ __html: archiveText }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedFilter;
